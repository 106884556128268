<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters class="d-flex flex-row align-center pt-3 px-0 flex-md-wrap col-12">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row">
                        <v-text-field
                            :placeholder="$t('message.invoice')"
                            :value="searchTerm"
                            autocomplete="new-password"
                            class="filter-width-200 force-text-left"
                            clearable
                            dense
                            hide-details="auto"
                            prepend-inner-icon="search"
                            solo
                            @change="searchTerm = $event"
                        />
                        <v-btn
                            :loading="loading.clear"
                            class="ml-2 px-2 mt-1"
                            @click="searchJobs()"
                        >{{ $t('message.search') }}</v-btn>
                    </div>
                    <div class="d-flex flex-row align-center mt-3">
                        <v-select
                            :items="documentStatus"
                            :placeholder="$t('message.status')"
                            class="filter-width-120 ml-0"
                            autocomplete="off"
                            clearable
                            dense
                            hide-details="auto"
                            item-value="Customer.id"
                            item-text="Customer.title"
                            solo
                            v-model="filterValues.status"
                            @change="getJobs()"
                        ></v-select>
                        <v-autocomplete
                            :items="buyers"
                            :loading="loading.filter.buyers"
                            :placeholder="$t('message.buyers')"
                            class="filter-width-200 ml-2"
                            autocomplete="off"
                            clearable
                            dense
                            hide-details="auto"
                            item-value="Customer.id"
                            item-text="Customer.title"
                            solo
                            v-model="filterValues.buyer"
                            @change="getJobs()"
                        />
                        <v-select
                            :items="years"
                            :placeholder="$t('message.year')"
                            class="filter-width-120 ml-2"
                            dense
                            hide-details="auto"
                            item-value="value"
                            item-text="text"
                            solo
                            v-model="filterValues.year"
                            @change="getJobs()()"
                        />
                        <v-select
                            :items="months"
                            :placeholder="$t('message.month')"
                            :disabled="filterValues.year == null"
                            class="filter-width-120 ml-2"
                            clearable
                            dense
                            hide-details="auto"
                            item-value="Month.id"
                            item-text="Month.text"
                            solo
                            v-model="filterValues.month"
                            @change="getJobs()"
                        />
                        <v-btn
                            :loading="loading.clear"
                            class="ml-2 px-2"
                            @click="clearFilters"
                        >{{ $t('message.clear') }}</v-btn>
                    </div>
                </div>
                <v-spacer></v-spacer>
                <div class="notes d-flex flex-row px-3 py-1 rounded">
                    <div class="d-flex flex-column">
                        <span class="note">1. Valid invoices can be cancelled within 72 hours of validation date.</span>
                        <span class="note">2. Invoices beyond the 72 hrs that need to be cancelled require reversion using a CN.</span>
                        <span class="note">3. Invoices require a CN to decrease the amount.</span>
                    </div>
                    <div class="d-flex flex-column align-top" style="height: 100%">
                        <span class="note ml-3">4. Invoices require a DN to increase the amount.</span>
                        <span class="note ml-3">5. Invalid invoices can be resubmitted after correction.</span>
                    </div>
                </div>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.items"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="invoices"
            :options="tableOptions"
            :server-items-length="totalInvoices"
            :search="searchTerm"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="lhdnInvoicesTable"
            item-key="job_id"
            @update:options="updateDataTable"
        >
            <template v-slot:item.document_status="{ item }">
                <div class="red--text text-darken-1" v-if="['Invalid','Rejected'].includes(item.document_status)">{{ item.document_status }}</div>
                <div class="green--text text-darken-1" v-else-if="item.document_status === 'Valid'">{{ item.document_status }}</div>
                <div class="blue--text text-darken-1" v-else-if="item.document_status === 'Cancelled'">{{ item.document_status }}</div>
                <div v-else>{{ item.document_status }}</div>
            </template>
            <template v-slot:item.invoice_number="{ item }">
                <div class="font-weight-bold">{{ item.invoice_number }}</div>
            </template>
            <template v-slot:item.contract_number="{ item }">
                <div class="font-weight-bold">{{ ['DS','CN','CS','DN'].includes(item.appic_document_type) ? item.contract_number : item.salesorder_number }}</div>
            </template>
            <template v-slot:item.amount="{ item }">
                <div class="text-right text-no-wrap">{{ formatThisNumber((item.amount), '0,0.00') }}</div>
            </template>
            <template v-slot:item.lhdn_received="{ item }">
                <div>{{ item.lhdn_received }}</div>
            </template>
            <template v-slot:item.lhdn_validated="{ item }">
                <div>{{ item.lhdn_validated }}</div>
            </template>
            <template v-slot:item.lhdn_validation_link="{ item }">
                <template v-if="item.lhdn_validation_link != null">
                    <v-tooltip bottom>
                        <template  v-slot:activator="{ on, attrs }">
                            <v-btn
                                :href="item.lhdn_validation_link"
                                class="ml-5"
                                target="_blank"
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon color="green darken-2">mdi-link</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.validationLink') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.rejection_reason="{ item }">
                <template v-if="item.rejection_reason.length > 0">
                    <v-btn class="my-2" dense small @click="viewRejectionReason(item.invoice_number, item.rejection_reason)" color="warning">{{ $t('message.viewReason') }}</v-btn>
                </template>
                <template v-else>
                    <span>{{ $t('message.na') }}</span>
                </template>
            </template>
            <template v-slot:item.lhdn_errors="{ item }">
                <template v-if="item.lhdn_errors.length > 0">
                    <v-btn class="my-2" dense small @click="viewLhdnErrors(item.invoice_number, item.lhdn_errors)" color="warning">{{ $t('message.viewErrors') }}</v-btn>
                </template>
                <template v-else>
                    <span>{{ $t('message.na') }}</span>
                </template>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="d-flex flex-row py-2">
                    <PrintLhdnDebitNote :job-id="item.job_id"/>
                    <v-btn small class="ml-2" @click="openCancelDialog(item.job_id, item.lhdn_document_uuid, item.invoice_number)" v-if="item.document_status === 'Valid' && item.is_cancellable">{{ $t('message.cancel') }}</v-btn>
<!--                    <v-btn small class="ml-2" @click="openRevertDialog(item.job_id, item.lhdn_document_uuid, item.invoice_number)" v-if="item.document_status === 'Valid' && !item.is_cancellable">{{ $t('message.revert') }}</v-btn>-->
<!--                    <v-btn small class="ml-2" @click="openAdjustDialog(item.job_id, item.lhdn_document_uuid, item.invoice_number)" v-if="item.document_status === 'Valid' && !['CN', 'DN'].includes(item.appic_document_type) && !item.is_cancellable">{{ $t('message.deduct') }}</v-btn>-->
<!--                    <v-btn small class="ml-2" @click="openAddToDialog(item.job_id, item.lhdn_document_uuid, item.invoice_number)" v-if="item.document_status === 'Valid' && !['CN', 'DN'].includes(item.appic_document_type) && !item.is_cancellable">{{ $t('message.add') }}</v-btn>-->
                    <v-btn small class="ml-2" :loading="loading.resubmit && item.job_id == currentJobId" @click="resubmitJob(item.appic_document_id, item.appic_document_type, item.invoice_number, item.job_id)" v-if="item.document_status === 'Invalid'">{{ $t('message.resubmit') }}</v-btn>
                    <v-btn small class="ml-2" color="warning" :loading="loading.updateStatus && item.job_id == currentJobId" @click="updateStatus(item.job_id)" v-if="item.document_status === 'Submitted'">{{ $t('message.updateStatus') }}</v-btn>
                    <v-btn small class="ml-2" color="danger" :loading="loading.deleteJob && item.job_id == currentJobId" @click="deleteJob(item.job_id, item.invoice_number)" v-if="['Invalid','Rejected'].includes(item.document_status)">{{ $t('message.deleteJob') }}</v-btn>
                </div>
            </template>
            <template v-slot:item.posted="{ item }">
                <v-btn small class="ml-2" @click="openPostToXeroDialog(item.job_id, item.lhdn_document_uuid, item.invoice_number)" v-if="item.posted == null && item.document_status === 'Valid'">{{ $t('message.postToXero') }}</v-btn>
                <div class="ml-3" v-if="item.posted !== null">{{ item.posted }}</div>
            </template>
        </v-data-table>
        <ViewError
            :job="selectedLhdnJob"
            :errors="selectedLhdnErrors"
            :dialog.sync="errorDialog"
            @dialog-closed="errorDialogClosed"
        ></ViewError>
        <AdjustDocument
            :invoice-number="selectedLhdnDocumentInvoiceNumber"
            :job-id="selectedLhdnDocumentJobId"
            :document-uuid="selectedLhdnDocumentUuid"
            :dialog.sync="adjustDialog"
            @dialog-closed="adjustDialogClosed"
            @adjust-done="documentAdjusted"
        ></AdjustDocument>
        <AddChargesToDocument
            :invoice-number="selectedLhdnDocumentInvoiceNumber"
            :job-id="selectedLhdnDocumentJobId"
            :document-uuid="selectedLhdnDocumentUuid"
            :dialog.sync="addToDialog"
            @dialog-closed="addToDialogClosed"
            @add-to-done="documentAddedTo"
        ></AddChargesToDocument>
        <CancelDocument
            :invoice-number="selectedLhdnDocumentInvoiceNumber"
            :job-id="selectedLhdnDocumentJobId"
            :document-uuid="selectedLhdnDocumentUuid"
            :dialog.sync="cancelDialog"
            @dialog-closed="cancelDialogClosed"
            @cancel-done="documentCancelled"
        ></CancelDocument>
        <RevertDocument
            :invoice-number="selectedLhdnDocumentInvoiceNumber"
            :job-id="selectedLhdnDocumentJobId"
            :document-uuid="selectedLhdnDocumentUuid"
            :dialog.sync="revertDialog"
            @dialog-closed="revertDialogClosed"
            @revert-done="documentReverted"
        ></RevertDocument>
        <UploadDocumentToXero
            :invoice-number="selectedLhdnDocumentInvoiceNumber"
            :job-id="selectedLhdnDocumentJobId"
            :document-uuid="selectedLhdnDocumentUuid"
            :dialog.sync="postDialog"
            @dialog-closed="postDialogClosed"
            @post-done="documentPosted"
        ></UploadDocumentToXero>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {api} from "Api";
import { formatDate, isObject, numberFormat} from "Helpers/helpers";

const AddChargesToDocument = () => import("Components/Appic/Lhdn/AddChargesToDocument.vue");
const AdjustDocument = () => import("Components/Appic/Lhdn/AdjustDocument.vue");
const CancelDocument = () => import("Components/Appic/Lhdn/CancelDocument.vue");
const PrintLhdnDebitNote = () => import("Components/Appic/Lhdn/PrintLhdnDebitNote.vue");
const RevertDocument = () => import("Components/Appic/Lhdn/RevertDocument.vue");
const UploadDocumentToXero = () => import("Components/Appic/Lhdn/UploadDocumentToXero.vue");
const ViewError = () => import("Components/Appic/Lhdn/ViewError");

export default {
    name: "LhdnDebitNotes",
    components: {PrintLhdnDebitNote, AddChargesToDocument, AdjustDocument, CancelDocument, RevertDocument, UploadDocumentToXero, ViewError },
    title: '',
    data() {
        return {
            addToDialog: false,
            adjustDialog: false,
            buyers: [],
            cancelDialog: false,
            currentJobId: null,
            documentStatus: [
                'Valid',
                'Invalid',
                'Submitted',
                'Cancelled'
            ],
            errorDialog: false,
            filterValues: {
                buyer: null,
                month: null,
                status: null,
                year: 2024
            },
            invoices: [],
            loader: false,
            loading: {
                clear: false,
                filterResults: false,
                filter: {
                    buyers: false
                },
                search: false,
                items: false,
                updateStatus: false,
                resubmit: false,
                deleteJob: false
            },
            postDialog: false,
            revertDialog: false,
            searchTerm: null,
            selectedLhdnJob: null,
            selectedLhdnErrors: null,
            selectedLhdnDocumentInvoiceNumber: null,
            selectedLhdnDocumentJobId: null,
            selectedLhdnDocumentUuid: null,
            tableOptions: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['document_status', 'lhdn_received'],
                sortDesc: [false, true]
            },
            totalInvoices: 0,
        }
    },
    computed: {
        ...mapGetters([
            'months'
        ]),
        defaultItemsPerPage() {
            return 10
        },
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.status'),
                    value: 'document_status',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 2,
                    text: this.$t('message.type'),
                    value: 'transaction',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 3,
                    text: 'e-DN No',
                    value: 'invoice_number',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 4,
                    text: this.$t('message.contractSalesOrder'),
                    value: 'contract_number',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 5,
                    text: this.$t('message.remark'),
                    value: 'user_remark',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 6,
                    text: this.$t('message.salesType'),
                    value: 'appic_document_type',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 7,
                    text: this.$t('message.buyerSupplier'),
                    value: 'buyer',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 8,
                    text: this.$t('message.date'),
                    value: 'invoice_date',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 9,
                    text: this.$t('message.amount'),
                    value: 'amount',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 10,
                    text: this.$t('message.jobStatus'),
                    value: 'job_status',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'green-cell'
                },
                {
                    id: 11,
                    text: this.$t('message.lhdnReceived'),
                    value: 'lhdn_received',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 12,
                    text: this.$t('message.lhdnValidated'),
                    value: 'lhdn_validated',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 13,
                    text: this.$t('message.lhdnLink'),
                    value: 'lhdn_validation_link',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 14,
                    text: this.$t('message.rejectionReason'),
                    value: 'rejection_reason',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 15,
                    text: this.$t('message.lhdnErrors'),
                    value: 'lhdn_errors',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    id: 16,
                    text: this.$t('message.actions'),
                    value: 'actions',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-3 font-sm text-left',
                },
                {
                    id: 17,
                    text: this.$t('message.xeroDatePosted'),
                    value: 'posted',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-3 font-sm text-left',
                }
            ]
        },
        itemsPerPageOptions() {
            return [10, 20, 50, -1];
        },
        years() {
            let years = []
            let dt = new Date()
            const startYear = dt.getFullYear()
            const endYear = 2024
            for(let i = startYear; i >= endYear; i--){
                years.push({value: i, text: i.toString()})
            }
            return years
        }
    },
    methods: {
        addToDialogClosed() {
            this.addToDialog = false;
            this.selectedLhdnDocumentUuid = null;
            this.selectedLhdnDocumentJobId = null;
            this.selectedLhdnDocumentInvoiceNumber = null;
        },
        adjustDialogClosed() {
            this.adjustDialog = false;
            this.selectedLhdnDocumentUuid = null;
            this.selectedLhdnDocumentJobId = null;
            this.selectedLhdnDocumentInvoiceNumber = null;
        },
        cancelDialogClosed() {
            this.cancelDialog = false;
            this.selectedLhdnDocumentUuid = null;
            this.selectedLhdnDocumentJobId = null;
            this.selectedLhdnDocumentInvoiceNumber = null;
        },
        clearFilters() {
            this.searchTerm = null
            this.filterValues.buyer = null
            this.filterValues.month = null
            this.filterValues.status = null
            const dt = new Date()
            this.filterValues.year = dt.getFullYear()
            this.tableOptions.page = 1
            this.tableOptions.sortBy[0] = 'document_status'
            this.tableOptions.sortDesc[0] = false
            this.tableOptions.sortBy[1] = 'lhdn_received'
            this.tableOptions.sortDesc[1] = true
            this.loading.clear = false
            this.getJobs()
        },
        async deleteJob(jobId, invoiceNumber) {
            if(await this.$root.$confirm(this.$t('message.deleteJob') + ' ' +  invoiceNumber, this.$t('message.confirmations.continueJobCancelAction'), {color: 'orange'})){
                this.loading.deleteJob = true;
                this.currentJobId = jobId;
                api
                    .delete('/lhdn/jobs/' + jobId)
                    .then((response) => {
                        if(response.data.status === 'success'){
                            this.$toast.success(this.$t('message.successes.jobDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.getJobs();
                        } else {
                            this.$toast.error(this.$t('message.errors.jobNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.deleteJob = false;
                        this.currentJobId = null;
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.jobNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.deleteJob = false;
                        this.currentJobId = null;
                    })
            }
        },
        documentAddedTo() {
            this.addToDialog = false;
            this.selectedLhdnDocumentUuid = null;
            this.selectedLhdnDocumentJobId = null;
            this.selectedLhdnDocumentInvoiceNumber = null;
            this.getJobs();
        },
        documentAdjusted() {
            this.adjustDialog = false;
            this.selectedLhdnDocumentUuid = null;
            this.selectedLhdnDocumentJobId = null;
            this.selectedLhdnDocumentInvoiceNumber = null;
            this.getJobs();
        },
        documentCancelled() {
            this.cancelDialog = false;
            this.selectedLhdnDocumentUuid = null;
            this.selectedLhdnDocumentJobId = null;
            this.selectedLhdnDocumentInvoiceNumber = null;
            this.getJobs();
        },
        documentPosted() {
            this.postDialog = false;
            this.selectedLhdnDocumentUuid = null;
            this.selectedLhdnDocumentJobId = null;
            this.selectedLhdnDocumentInvoiceNumber = null;
            this.getJobs();
        },
        documentReverted() {
            this.revertDialog = false;
            this.selectedLhdnDocumentUuid = null;
            this.selectedLhdnDocumentJobId = null;
            this.selectedLhdnDocumentInvoiceNumber = null;
            this.getJobs();
        },
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        errorDialogClosed() {
            this.errorDialog = false;
            this.selectedLhdnJob = null;
            this.selectedLhdnErrors = null;
        },
        getBuyers() {
            this.loading.filter.buyers = true
            api
                .get('/lhdn/jobs/buyers')
                .then((response)=>{
                    if(response.data.status === 'success') {
                        this.buyers = response.data.data
                    } else {
                        if(isObject(response.data.message)){
                            //extract validation errors
                            const errors = Object.values(response.data.message)
                            errors.forEach(errorObj => {
                                if (Array.isArray(errorObj)) {
                                    errorObj.forEach(error => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    }
                    this.loading.filter.buyers = false
                })
                .catch((error) => {
                    this.loading.filter.buyers = false
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        getJobs() {
            this.loading.items = true
            let conditions = [];

            conditions.push({
                field: 'transaction',
                value: 'Debit Note'
            })

            if (this.filterValues.status != null) conditions.push({
                field: 'status',
                value: this.filterValues.status
            })
            if (this.filterValues.buyer != null) conditions.push({
                field: 'customer_id',
                value: this.filterValues.buyer
            })
            if (this.filterValues.month != null) conditions.push({
                field: 'month',
                value: this.filterValues.month
            })
            if (this.filterValues.year != null) conditions.push({
                field: 'year',
                value: this.filterValues.year
            })
            let order = [];
            this.tableOptions.sortBy.forEach((sort, idx) => {
                order.push(
                    {
                        field: sort,
                        direction: this.tableOptions.sortDesc[idx] ? 'DESC' : 'ASC'
                    }
                )
            })
            api
                .get('/lhdn/jobs', {
                    params: {
                        conditions: conditions,
                        pagination: {
                            skip: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
                            take: this.tableOptions.itemsPerPage
                        },
                        order: order
                    }
                })
                .then((response)=>{
                    if(response.data.status === 'success') {
                        this.invoices = response.data.data
                        this.totalInvoices = response.data.totalRows
                    } else {
                        if(isObject(response.data.message)){

                            //extract validation errors
                            const errors = Object.values(response.data.message)

                            errors.forEach(errorObj => {
                                if (Array.isArray(errorObj)) {
                                    errorObj.forEach(error => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    }
                    this.loading.items = false
                    this.$emit('update-done');
                })
                .catch((error) => {
                    this.loading.items = false
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (230);
        },
        openAddToDialog(jobId, uuid, invoiceNumber) {
            this.selectedLhdnDocumentJobId = jobId
            this.selectedLhdnDocumentUuid = uuid
            this.selectedLhdnDocumentInvoiceNumber = invoiceNumber
            this.addToDialog = true
        },
        openAdjustDialog(jobId, uuid, invoiceNumber) {
            this.selectedLhdnDocumentJobId = jobId
            this.selectedLhdnDocumentUuid = uuid
            this.selectedLhdnDocumentInvoiceNumber = invoiceNumber
            this.adjustDialog = true
        },
        openCancelDialog(jobId, uuid, invoiceNumber) {
            this.selectedLhdnDocumentJobId = jobId
            this.selectedLhdnDocumentUuid = uuid
            this.selectedLhdnDocumentInvoiceNumber = invoiceNumber
            this.cancelDialog = true
        },
        openPostToXeroDialog(jobId, uuid, invoiceNumber) {
            this.selectedLhdnDocumentJobId = jobId
            this.selectedLhdnDocumentUuid = uuid
            this.selectedLhdnDocumentInvoiceNumber = invoiceNumber
            this.postDialog = true
        },
        openRevertDialog(jobId, uuid, invoiceNumber) {
            this.selectedLhdnDocumentJobId = jobId
            this.selectedLhdnDocumentUuid = uuid
            this.selectedLhdnDocumentInvoiceNumber = invoiceNumber
            this.revertDialog = true
        },
        postDialogClosed() {
            this.postDialog = false;
            this.selectedLhdnDocumentUuid = null;
            this.selectedLhdnDocumentJobId = null;
            this.selectedLhdnDocumentInvoiceNumber = null;
        },
        async resubmitJob(documentId, documentType, invoiceNumber, jobId) {
            if(await this.$root.$confirm(this.$t('message.resubmitInvoice') + ' ' +  invoiceNumber, this.$t('message.confirmations.continueInvoiceResubmitAction'), {color: 'orange'})){
                this.loading.resubmit = true;
                this.currentJobId = jobId;
                const promise = new Promise((resolve, reject) => {
                    api
                        .post("/lhdn/document", {
                            document_id:  documentId,
                            document_type: documentType.toLowerCase()
                        })
                        .then(response => {
                            if (response.data.status === 'success') {
                                resolve(response.data)
                            } else {
                                resolve(response.data.message)
                            }
                        })
                        .catch(() => {
                            reject('error');
                        })
                })
                promise
                    .then((response) => {
                        this.loading.resubmit = false;
                        this.currentJobId = null;
                        if (response.status === 'success') {
                            this.$toast.success(this.$t('message.successes.invoiceSubmitted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            );
                            this.getJobs()
                        } else {
                            this.$toast.error(response,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            );
                        }
                    })
                    .catch(() => {
                        this.loading.resubmit = false;
                        this.currentJobId = null;
                        this.$toast.error(this.$t('message.errors.invoiceNotSubmitted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        );
                    })
            }
        },
        revertDialogClosed() {
            this.revertDialog = false;
            this.selectedLhdnDocumentUuid = null;
            this.selectedLhdnDocumentJobId = null;
            this.selectedLhdnDocumentInvoiceNumber = null;
        },
        searchJobs() {
            this.loading.items = true

            this.filterValues.year = null;

            let conditions = [];

            conditions.push({
                field: 'transaction',
                value: 'Debit Note'
            })

            if (this.searchTerm != null) conditions.push({
                field: 'search',
                value: this.searchTerm
            })

            let order = [];
            this.tableOptions.sortBy.forEach((sort, idx) => {
                order.push(
                    {
                        field: sort,
                        direction: this.tableOptions.sortDesc[idx] ? 'DESC' : 'ASC'
                    }
                )
            })

            api
                .get('/lhdn/jobs', {
                    params: {
                        conditions: conditions,
                        pagination: {
                            skip: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
                            take: this.tableOptions.itemsPerPage
                        },
                        order: order
                    }
                })
                .then((response)=>{
                    if(response.data.status === 'success') {
                        this.invoices = response.data.data
                        this.totalInvoices = response.data.totalRows
                    } else {
                        if(isObject(response.data.message)){
                            //extract validation errors
                            const errors = Object.values(response.data.message)
                            errors.forEach(errorObj => {
                                if (Array.isArray(errorObj)) {
                                    errorObj.forEach(error => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    }
                    this.loading.items = false
                })
                .catch((error) => {
                    this.loading.items = false
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        updateDataTable(options) {
            this.tableOptions = options
            this.getJobs()
        },
        updateStatus(jobId) {
            this.loading.updateStatus = true;
            this.currentJobId = jobId;
            api
                .get("/lhdn/jobs/status/" + jobId)
                .then(response => {
                    this.loading.updateStatus = false;
                    this.currentJobId = null;
                    if (response.data.status === 'success') {
                        this.getJobs();
                    } else {
                        this.$toast.error(this.$t('message.errors.statusNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        );
                    }
                })
                .catch(() => {
                    this.loading.updateStatus = false;
                    this.currentJobId = null;
                    this.$toast.error(this.$t('message.errors.statusNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    );
                })
        },
        viewRejectionReason(job, errors) {
            this.selectedLhdnJob = job
            this.selectedLhdnErrors = errors
            this.errorDialog = true
        },
        viewLhdnErrors(job, errors) {
            this.selectedLhdnJob = job
            this.selectedLhdnErrors = errors
            this.errorDialog = true
        }
    },
    watch: {
        scrollingTable(value){
            localStorage.setItem('electronicInvoicesScrollingTable',value)
            if(value){
                this.handleResize()
            }
        }
    },
    created() {
        if(localStorage.getItem('electronicInvoicesScrollingTable') !== null){
            this.scrollingTable = localStorage.getItem('electronicInvoicesScrollingTable') === 'true' ? true : null
        }
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
        const dt = new Date()
        this.filterValues.year = dt.getFullYear()
        this.tableOptions.itemsPerPage = this.defaultItemsPerPage
        this.tableOptions.sortBy[0] = 'document_status'
        this.tableOptions.sortDesc[0] = false
        this.tableOptions.sortBy[1] = 'lhdn_received'
        this.tableOptions.sortDesc[1] = true
        this.getBuyers()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.filter-width-120 {
    width: 120px !important;
    max-width: 120px !important;
}
.filter-width-200 {
    width: 185px !important;
    max-width: 185px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table tbody td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.height-10px {
    height: 10px;
}
.v-data-table-header th {
    white-space: nowrap;
}
.note {
    font-size: 0.8em;
}
.notes {
    border: 1px solid #3C6932;
}
</style>